@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500&family=Red+Hat+Display:wght@300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing:border-box;
  -ms-overflow-style: none;
}

body {
  font-family: 'Red Hat Display', sans-serif;
  /* background: #fff; */
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: url(https://database-upload.v3.microgen.id/file/storage/642a3f45e54f9cd0516fc55d/bgweb-XxUQroed.svg); */
  /* width: 100%; */
}

::-webkit-scrollbar {
  display: none;
}
html,body{
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.bg-img{
  background-size: auto;
  background-color: #000
}

@tailwind base;
@tailwind components;
@tailwind utilities;


